// extracted by mini-css-extract-plugin
export var centeredTitle = "c0_jf";
export var childrenContainer = "c0_jh";
export var container = "c0_f";
export var dark = "c0_ds";
export var innerContainer = "c0_cs";
export var left = "c0_cJ";
export var light = "c0_dr";
export var noBottomPadding = "c0_h9";
export var noRectangle = "c0_jd";
export var noTopPadding = "c0_jb";
export var overflowHidden = "c0_jc";
export var right = "c0_cK";
export var subtitle = "c0_k";
export var tan = "c0_cF";
export var textContainer = "c0_bP";
export var textContainerCustomWidth = "c0_jg";