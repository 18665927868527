// extracted by mini-css-extract-plugin
export var border1 = "cX_hZ";
export var border2 = "cX_h0";
export var border3 = "cX_h1";
export var border4 = "cX_h2";
export var container = "cX_f";
export var dark = "cX_ds";
export var large = "cX_b8";
export var ldsRing = "cX_h3";
export var light = "cX_dr";
export var medium = "cX_b9";
export var primaryPressed = "cX_h4";
export var xlarge = "cX_hY";