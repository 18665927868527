// extracted by mini-css-extract-plugin
export var button = "Q_v";
export var children = "Q_cd";
export var disabled = "Q_b5";
export var fullWidth = "Q_cc";
export var iconWrapper = "Q_cg";
export var large = "Q_b8";
export var link = "Q_bV";
export var loading = "Q_b4";
export var loadingContainer = "Q_cf";
export var medium = "Q_b9";
export var primary = "Q_b3";
export var secondary = "Q_b6";
export var small = "Q_cb";
export var tertiary = "Q_b7";